export default function connect ({ next, store }) {
  let data = store.state.settings.settings;
  let account = store.state.auth.account;
  let currentPlan = store.state.plan.currentPlan;

  if (account.role === 1 && (!currentPlan || currentPlan.past_due || !data.amazon_user_id)) {
    return next({
      name: 'settings'
    })
  }

  return next()
}