import auth from "../middlewares/auth";
import connect from "@/middlewares/connect";
export const reportRoutes = [
  {
    path: '/reports/spend',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/reports/SpendReport'),
    name: 'reports_spend',
    meta: {
      middleware: [
        auth, connect
      ]
    }
  },
];