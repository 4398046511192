import { createStore } from 'vuex'
import authModule from './modules/auth'
import adAccounts from './modules/adAccounts'
import settings from './modules/settings'
import planModules from './modules/planModules'
import fbAdsModule from './modules/fbAdsModule'
import googleWizardModule from './modules/googleWizardModule'
import subscriptionModule from './modules/subscriptionModule'

const store = createStore({
  state () {
    return {

    }
  },
  mutations: {

  },
  actions: {

  },
  getters: {
  },

  modules: {
    auth: authModule,
    adAccounts: adAccounts,
    settings: settings,
    plan: planModules,
    fbAds: fbAdsModule,
    googleWizard: googleWizardModule,
    subscriptionModule: subscriptionModule,
  }
})

export default store