import auth from "../middlewares/auth";
import connect from "@/middlewares/connect";
import editor from "@/middlewares/editor";
import hasPlan from "@/middlewares/hasPlan";
export const createCampaignRoutes = [
  {
    path: '/google-wizard',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/create_campaign/google_wizard/Template'),
    name: 'google_wizard',
    meta: {
      middleware: [
        auth, connect, editor, hasPlan
      ]
    }
  },
  {
    path: '/create-campaign/facebook',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/create_campaign/Facebook'),
    name: 'create_campaign_facebook',
    meta: {
      middleware: [
        auth, connect, editor, hasPlan
      ]
    }
  },
  {
    path: '/create-campaign/influencer',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/create_campaign/Influencer'),
    name: 'create_influencer',
    meta: {
      middleware: [
        auth, connect, editor, hasPlan
      ]
    }
  },
  {
    path: '/create-campaign/send-mail/:id',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/create_campaign/InfluencerAweber.vue'),
    name: 'influencer_send_mail',
    meta: {
      middleware: [
        auth, connect, editor, hasPlan
      ]
    }
  },
  {
    path: '/create-campaign/influencer/:id',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/create_campaign/InfluencerDetails'),
    name: 'influencer_details',
    meta: {
      middleware: [
        auth, connect, editor, hasPlan
      ]
    }
  },
  /*{
    path: '/create-campaign/google',
    component: () => import(/!* webpackChunkName: "jobs" *!/ '../views/create_campaign/Google'),
    name: 'create_campaign_google',
    meta: {
      middleware: [
        auth, connect
      ]
    }
  },*/
];