import auth from "../middlewares/auth";
import manager from "@/middlewares/manager";
export const teamRoutes = [
  {
    path: '/team',
    component: () => import(/* webpackChunkName: "team" */ '../views/team/List.vue'),
    name: 'team',
    meta: {
      middleware: [
        auth, manager
      ]
    }
  },
];