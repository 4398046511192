<template>
  <div>
    <!-- Tab Mobile View Header -->
    <header class="tabMobileView header navbar fixed-top d-lg-none">
      <div class="nav-toggle">
        <a href="javascript:void(0);" class="nav-link sidebarCollapse" data-placement="bottom">
          <i class="flaticon-menu-line-2"></i>
        </a>
        <a href="index.html" class="">
          <img :src="baseUrl + 'assets/images/logo.png'" class="img-fluid" alt="logo" />
        </a>
      </div>
      <ul class="nav navbar-nav">
        <li class="nav-item dropdown user-profile-dropdown ml-lg-0 mr-lg-2 ml-0 order-lg-0 order-1 d-lg-none">
          <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="flaticon-user-12"></i>
          </a>
          <div class="dropdown-menu  position-absolute" aria-labelledby="userProfileDropdown">
            <router-link class="dropdown-item"
                         active-class="active22"
                         :to="{name: 'profile'}">
              <i data-feather="user"></i> Profile
            </router-link>
            <a class="dropdown-item" @click.prevent="logout" href="#">
              <i data-feather="power"></i> Logout
            </a>
          </div>
        </li>
      </ul>
    </header>
    <!-- Tab Mobile View Header -->

    <!--  BEGIN NAVBAR  -->
    <header class="header navbar fixed-top navbar-expand-sm d-flex justify-content-end d-none">
      <a href="javascript:void(0);" class="sidebarCollapse d-none d-lg-block" data-placement="bottom"><i class="flaticon-menu-line-2"></i></a>

      <ul class="navbar-nav flex-row ml-lg-auto">
        <li class="nav-item dropdown user-profile-dropdown ml-lg-0 mr-lg-2 ml-0 order-lg-0 order-1" v-if="account">
          <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="d-flex">
              <div class="mr-2" style="line-height: 15px !important;">
                <span class="fs-15">{{ accountName }}<br>
                  <span class="fs-10" v-if="currentPlan && currentPlan.is_influencer">Collab</span>
                  <span class="fs-10" v-else-if="account.role === 1">Owner</span>
                  <span class="fs-10" v-else-if="account.role === 2">Manager</span>
                  <span class="fs-10" v-else-if="account.role === 3">Viewer</span>
                  <span class="fs-10" v-else-if="account.role === 4">Editor</span>
                  <span class="fs-10" v-else-if="account.role === 5">Agency</span>
                </span>
              </div>
              <div class="ms-auto bd-highlight">
                <i class="flaticon-user-6"></i>
              </div>
            </div>
          </a>
          <div class="dropdown-menu  position-absolute"
               v-if="user && account"
               aria-labelledby="userProfileDropdown">
            <a class="dropdown-item"
               href="#"
               @click.prevent="$store.dispatch('auth/changeAccount', account)"
               v-for="account in user.accounts.filter(a => a.id !== account.id)"
               :key="'account_'+account.id">
              <i class="mr-1 flaticon-user-6"></i> <span>{{ account.account ? account.account.name : (account.agency ? account.agency.name : '') }}</span>
            </a>

            <div class="dropdown-divider"
                 v-if="user.accounts.filter(a => a.id !== account.id).length"></div>

            <a class="dropdown-item"
               v-show="currentPlan && currentPlan.is_influencer"
               @click.prevent="upgradePlan"
               href="#">
              <i data-feather="package"></i> Upgrade
            </a>

            <router-link class="dropdown-item"
                         active-class="active22"
                         :to="{name: 'profile'}">
              <i data-feather="user"></i> Profile
            </router-link>
            <a class="dropdown-item" @click.prevent="logout" href="#">
              <i data-feather="power"></i> Logout
            </a>
          </div>
        </li>
      </ul>
    </header>
    <!--  END NAVBAR  -->
  </div>

  <upgrade-modal ref="upgradeModal" />
</template>

<script>
import {mapGetters} from "vuex";
import UpgradeModal from "@/components/UpgradeModal.vue";

export default {
  components: {UpgradeModal},
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      account: 'auth/getAccount',
      accountName: 'auth/getAccountName',
      currentPlan: 'plan/getCurrentPlan'
    })
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
        .finally(() => {
          this.$router.push({name: 'login'})
        })
    },
    upgradePlan() {
      this.loading = true;

      this.axios.post('/upgrade-influencer')
        .then((res) => {
          window.location.href = res.data.data.url;
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>